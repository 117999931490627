import React, { Component } from "react";
import { View, Text, Image, Pressable } from "react-native";

import { IAttachment } from "./interface";
import { styles } from "./styles";

import inventoryImage from "../../assets/images/image_inventory_2.png";
import rightButtonImage from "../../assets/images/button_icon.png";

class Attachment extends Component<IAttachment> {
  constructor(props: IAttachment) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      subTitle,
      titleStyle,
      subTitleStyle,
      progressPrecentage,
      progressStyle,
      containerStyle,
      centerContainerStyle,
      leftContainerStyle,
      rightContainerStyle,
      leftIcon,
      rightIcon,
      ...pressableProps
    } = this.props;

    return (
      <View style={[styles.container, styles.shadow, containerStyle]}>
        <View style={styles.innerContainer}>
          {progressPrecentage > 0 ? (
            <View
              style={[
                styles.progress,
                progressStyle,
                { width: `${progressPrecentage}%` },
              ]}
            />
          ) : null}
          <View style={[styles.leftContainer, leftContainerStyle]}>
            {leftIcon || (
              <Image
                style={{ width: 18, height: 18 }}
                source={inventoryImage}
              />
            )}
          </View>
          <View style={[styles.centerContainer, centerContainerStyle]}>
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            <Text style={[styles.subTitle, subTitleStyle]}>{subTitle}</Text>
          </View>
          <View style={[styles.rightContainer, rightContainerStyle]}>
            <Pressable {...pressableProps}>
              {rightIcon || (
                <Image
                  style={{ width: 22, height: 22 }}
                  source={rightButtonImage}
                />
              )}
            </Pressable>
          </View>
        </View>
      </View>
    );
  }
}
export default Attachment;
