import { StyleSheet, Platform } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 10,
    width: "100%",
    padding:8,
  },
  input: {
    fontSize: 16,
    fontWeight: "400",
    color: COLORS.COLOR_COOLGRAY,
    width: "100%",
    zIndex: 1,
    marginLeft: 4,
    ...Platform.select({
      web: { outlineStyle: "none" },
    }),
  },
  error: {
    fontSize: 10,
    color: COLORS.COLOR_RED,
    fontFamily: "Arial",
    marginTop: 4,
    marginLeft: 10,
  },
  fixes: {
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "700",
    fontSize: 16,
    color: COLORS.COLOR_GRAY,
    fontFamily: "Arial",
  },
  label: {
    fontSize: 16,
    fontWeight: "700",
    color: "#334155",
    marginBottom: 8,
  },
});
export default styles;
