import React, { Component } from "react";

import {
  ActivityIndicator,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
  Pressable,
  PressableProps,
} from "react-native";

import defaultStyles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import { makeRNCompatibleStyleArr } from "../../utils/makeRNCompatibleStyleArr";
import COLORS from "../../utils/colors";

export interface ButtonProps extends PressableProps {
  loading?: boolean;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  gradientColors?: [string, string];
  activityIndicatorColor?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  testID?: string;
  onPress?: () => void;
}

interface ButtonState {}

export default class Button extends Component<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
  }

  gradientColors = [COLORS.COLOR_LIGHTBLUE, COLORS.COLOR_MEDIUMBLUE];
  activityIndicatorColor = COLORS.COLOR_LIGHTPINK;
  color = COLORS.COLOR_WHITE;

  ButtonBase = () => {
    const { loading, text, textStyle, icon, iconPosition, testID } = this.props;

    const finalTextStyle = makeRNCompatibleStyleArr([
      defaultStyles.text,
      { color: this.color + `${loading ? "70" : ""}` },
      textStyle,
    ]);

    const flexDirection = iconPosition === "left" ? "row-reverse" : "row";
    const activityIndicatorColor = this.props.activityIndicatorColor || this.activityIndicatorColor;
    const activityIndicatorTestID = testID ? `${testID}-activityIndicator`: "activityIndicator";

    return (
      <View style={[defaultStyles.innerView]}>
        <View
          style={{
            display: "flex",
            flexDirection,
            alignItems: "center",
          }}
        >
          <Text style={finalTextStyle}>{text}</Text>
          {(icon || loading) && 
          <View 
            style={{
              marginLeft: flexDirection === "row" && text ? 6 : 0,
              marginRight: flexDirection === "row-reverse" && text ? 6 : 0,
            }}
          >
            {loading ? (
              <ActivityIndicator
                testID={activityIndicatorTestID}
                color={activityIndicatorColor}
                style={{ paddingLeft: 7 }}
              />
            ) : icon }
          </View>}
        </View>
        
      </View>
    );
  };

  ButtonWithGradient(props) {
    let { children, gradientColors, disabled, loading, testID, ...restProps } = props;
    
    const pressableDisabled = disabled || loading;
    const pressableTestID = testID ? `${testID}-buttonPressable`: "buttonPressable";
    gradientColors = gradientColors || this.gradientColors;

    return (
      <Pressable testID={pressableTestID} disabled={pressableDisabled} {...restProps}>
        <LinearGradientComponent
          style={defaultStyles.gradient}
          colors={gradientColors}
        >
          <this.ButtonBase />
        </LinearGradientComponent>
      </Pressable>
    );
  }

  Button(props) {
    let { disabled, loading, testID, ...restProps } = props;

    const pressableDisabled = disabled || loading;
    const pressableTestID = testID ? `${testID}-buttonPressable`: "buttonPressable";

    return (
      <Pressable testID={pressableTestID} disabled={pressableDisabled} {...restProps}>
        <this.ButtonBase />
      </Pressable>
    );
  }

  render() {
    let {
      disabled = false,
      gradientColors,
      style,
      ...restProps
    } = this.props;
    
    const styleTemporary = { height: 44 };

    const propsWithStyles = {
      disabled,
      gradientColors,
      style: makeRNCompatibleStyleArr([
        {
          ...styleTemporary,
          ...defaultStyles.button,
        },
        style,
        disabled && defaultStyles.disable,
      ]),
      ...restProps,
    };

    if (disabled) {
      this.color = COLORS.COLOR_DISABLE_GREY;
      gradientColors = null;
    }

    if (gradientColors?.length == 2) {
      return this.ButtonWithGradient(propsWithStyles);
    } else {
      return this.Button(propsWithStyles);
    }
  }
}
