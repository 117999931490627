import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    fontWeight: "500",
    color: COLORS.GREY_TEXT,
  },
  selectedText: {
    color: COLORS.COLOR_MEDIUMBLUE,
  },
  tab: {
    alignItems: "center",
    justifyContent: "center",
    color: COLORS.GREY_TEXT,
  },
  contentContainer: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  mainView: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
  container: {
    display: "flex",
    padding: 10,
    backgroundColor: COLORS.COLOR_WHITE,
  },
});

export default styles;
