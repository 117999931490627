import React, { Component } from "react";
import { FlatList, ViewStyle, TextStyle, PressableProps } from "react-native";
import styles from "./styles";
import SegmentedCtrlItem, { SegmentedCtrlItemData } from "./SegmentedCtrlItem";

import CONSTANTS from "../../utils/constants";

export interface SegmentedControlProps extends Omit<PressableProps, "onPress"> {
  size?: "small" | "medium" | "big";
  textStyle?: TextStyle;
  /** data to be displayed on the flatlist */
  dataSet?: Array<SegmentedCtrlItemData>;
  /** style for the flatlist container */
  containerStyle?: ViewStyle;
  /** style for the segmented control item container */
  itemStyle?: ViewStyle;
  /** selected background color for the selected segmented control item */
  itemSelectdBgColor?: string;
  /** selected text color for the selected segmented control item */
  itemSelectdTxtColor?: string;
  onPressItem: (item: SegmentedCtrlItemData) => void;
}

interface SegmentedControlState {
  selectedId: string | undefined;
}

export const getSize = (size: "small" | "medium" | "big") => {
  switch (size) {
    case CONSTANTS.SIZE_SMALL:
      return 32;
    case CONSTANTS.SIZE_BIG:
      return 56;
    case CONSTANTS.SIZE_MEDIUM:
    default:
      return 44;
  }
};

class SegmentedControl extends Component<
  SegmentedControlProps,
  SegmentedControlState
> {
  constructor(props: SegmentedControlProps) {
    super(props);
    this.state = {
      selectedId: undefined,
    };
  }

  onPressItem = (item: SegmentedCtrlItemData) => {
    this.setState({ selectedId: item?.id });
  };

  render() {
    const {
      size,
      textStyle,
      dataSet,
      containerStyle,
      itemStyle,
      itemSelectdBgColor = "",
      itemSelectdTxtColor = "",
      onPressItem,
      ...otherPressableProps
    } = this.props;
    const { selectedId } = this.state;

    return (
      <FlatList
        // testID={STRINGS_TESTCASE.SEGMENTCONTROL_COMPONENT}
        contentContainerStyle={[styles.flatlist, containerStyle]}
        data={dataSet}
        keyExtractor={(item: any) => `${item?.id}`}
        horizontal={true}
        renderItem={({ item, index }) => (
          <SegmentedCtrlItem
            item={item}
            index={index}
            //@ts-ignore
            size={getSize(size)}
            textStyle={textStyle}
            itemStyle={itemStyle}
            itemSelectdBgColor={itemSelectdBgColor}
            itemSelectdTxtColor={itemSelectdTxtColor}
            onPress={() => {
              this.onPressItem(item);
              onPressItem && onPressItem(item);
            }}
            selectedId={selectedId}
            {...otherPressableProps}
          />
        )}
      />
    );
  }
}

export default SegmentedControl;
