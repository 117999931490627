import React, { useMemo } from "react";
import { Dimensions, useWindowDimensions } from "react-native";
const {width} = Dimensions.get("window");


function getPlatformType() {

  if (width >= 320 && width<= 568) {
    return "phone";
  } else if (width >= 601 && width <= 1280) {
    return "tab";
  } else if (width >= 1280) {
    return "web";
  }
}
export const FULL_HEIGHT=Dimensions.get('window').height;

export const usePlatfomHook = () => {
  const {height, width} = useWindowDimensions();

  const deviceType = useMemo(() => {
    let screenType = "";
    let isMobile = false;
    let isTablet = false;
    let isDesktop = false;
    const aspectRatio = height/width;
    if (aspectRatio > 1.6) {
      isMobile = true;
      screenType =  "MOBILE"
    } else if (aspectRatio > 1.33) {
      isTablet = true;
      screenType =  "TABLET"
    } else {
      screenType = "DESKTOP"
      isDesktop = true;
    }
    
    return { screenType, isMobile, isTablet, isDesktop }
  }, [height, width])

  return deviceType
}


export default getPlatformType;