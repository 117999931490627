import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  textView: {
    flexDirection: "column",
    flex: 1,
  },
  text: {
    fontSize: 16,
    fontWeight: "400",
    color: COLORS.COLOR_LISTTEXT,
    // fontFamily: 'Arial',
  },
  subText: {
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.COLOR_SUBTEXT,
    // fontFamily: 'Arial',
  },
  itemSeparator: {
    height: 1,
    backgroundColor: COLORS.COLOR_LIGHTGRAY,
    width: "100%",
  },
  icon: {
    tintColor: COLORS.COLOR_LISTTEXT,
    marginRight: 12,
    height: 12,
    width: 16,
  },
  rightIcon: {
    tintColor: COLORS.COLOR_LISTTEXT,
    marginRight: 16,
    height: 12,
    width: 16,
  },
  switch: {
    marginRight: 10,
    alignItems: "center",
    display: "flex",
  },
  switchStyle: {
    height: 22,
  },
});

export default styles;
