import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  mainView: {
    margin: 20,
    width: 224,
  },
  blockView: {
    backgroundColor: COLORS.COLOR_BTNGRAY,
    borderRadius: 18,
    height: 224,
    justifyContent: "flex-end",
    padding: 16,
  },
  titleText: {
    fontWeight: "700",
    fontSize: 14,
    color: COLORS.COLOR_LIGHTBLACK,
    lineHeight: 22,
  },
  descText: {
    fontWeight: "400",
    fontSize: 14,
    color: COLORS.COLOR_GRAY,
    lineHeight: 22,
    // width: 224,
  },
  priceBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 4,
  },
  priceText: {
    fontWeight: "400",
    fontSize: 14,
    color: COLORS.COLOR_LIGHTBLACK,
    lineHeight: 22,
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: COLORS.COLOR_LIGHTBLUE,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    left: 12,
    zIndex: 1,
  },
  iconStyle: {
    height: 24,
    width: 24,
  },
  leftIconPosition: {
    height: 30,
    width: 30,
    borderRadius: 20,
    position: "relative",
    left: 10,
    zIndex: 1,
    marginTop: 112,
  },
  rightIconPosition: {
    height: 30,
    width: 30,
    borderRadius: 20,
    position: "relative",
    right: 20,
    zIndex: 1,
    marginTop: 135,
  },
});

export default styles;
