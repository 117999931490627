
import { Dimensions, StyleSheet } from "react-native";
import {
  horizontalScale,
  verticalScale,
} from "../../../utils/screenRatio";

export default StyleSheet.create({
   container: {
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection:"row",
    paddingHorizontal:15,
    flexWrap: "wrap",
    flex:1,
    display: 'flex',
  },
  headerLogoTextStyle: {
    color: "#334155",
    fontSize: 20,
    marginHorizontal: 10,
  },
  rightIconStyle: {
    padding:10
  },
  flexStyle: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical:10
  },
  navButtonNameStyle: {
    color:'#334155',
    fontSize: 12,
    paddingHorizontal:4
  },
  inputStyle:{
    borderRadius: 100, 
    minWidth: 360 
  },
  hamburgerStyle:{
    width:19.5,
    height:13,
    paddingRight:10,
    color:"black",
    marginHorizontal:10
  },
  drawerContainer: {
    position: "absolute",
    zIndex: 10,
    bottom:0,
    top:350,
    backgroundColor:"red",
    width:10,
    height:"100%"
  },
  desktopPadding: {
    paddingHorizontal : 5
  },
  rightIcons: {
    justifyContent:"flex-end"
  }
});