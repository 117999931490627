import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    height: 60,
    borderRadius: 4,
    borderLeftWidth: 4,
    width: 360,
    flexDirection: "row",
  },
  textContainer: {
    fontSize: 12,
    fontWeight: "400",
    paddingVertical: 16,
    paddingHorizontal: 12,
    // fontFamily: 'Arial',
  },
  iconContainer: {
    justifyContent: "center",
    position: "absolute",
    marginTop: 20,
    right: 8,
  },
  rightIconStyle: {
    height: 16,
    width: 16,
  },
});

export default styles;
