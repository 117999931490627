import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 8,
    padding: 8,
  },
  input: {
    flex: 1,
    fontSize: 16,
    fontWeight: "400",
    color: COLORS.COLOR_COOLGRAY,
    // outlineStyle: 'none',
    // fontFamily: 'Arial',
  },
  error: {
    fontSize: 14,
    fontWeight: "400",
    color: COLORS.COLOR_RED,
    marginTop: 2,
  },
  characterLimit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 2,
  },
  label: {
    fontSize: 16,
    fontWeight: "700",
    // fontFamily: 'Arial',
    color: COLORS.COLOR_SUBTEXT,
  },
  labelContainer: {
    marginBottom: 8,
    marginHorizontal: 4,
  },
});

export default styles;
