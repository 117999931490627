import { StyleSheet } from "react-native";
const hs = (value: number) => value;
const ws = (value: number) => value;
const ms = (value: number) => value;

export const styles = StyleSheet.create({
  containerStyle: {
    width: ws(369),
    backgroundColor: "white",
    borderWidth: ms(1),
    borderStyle: "dashed",
    borderColor: "#9747FF",
    borderRadius: ms(4),
    padding: ws(8),
  },
  itemContainer: {
    backgroundColor: "#fff",
    paddingHorizontal: ws(16),
    marginVertical: hs(8),
    borderRadius: ms(8),
    overflow: "hidden",
  },
  headerContainer: {
    width: "100%",
    maxHeight: hs(50),
    justifyContent: "space-between",
    paddingVertical: hs(2),
  },
  title: {
    flex: 1,
    // fontFamily: 'Arial',
    fontSize: ms(16),
    fontWeight: "400",
    color: "#000",
  },
  subTitle: {
    // fontFamily: 'Arial',
    fontSize: ms(14),
    fontWeight: "400",
    color: "#475569",
  },
  contentContainer: {
    width: "100%",
    justifyContent: "space-between",
    paddingVertical: hs(2),
    marginTop: hs(25),
  },
  sampleContent: {
    height: hs(142),
    width: "100%",
    backgroundColor: "#F8FAFC",
    borderRadius: 8,
  },
  iconStyle: {
    height: hs(24),
    width: ws(24),
    overflow: "hidden",
    resizeMode: "stretch",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
    resizeMode: "stretch",
    overflow: "hidden",
  },
});
