import React from "react";
import { Component } from "react";
import { View, Image } from "react-native";
import { Calendar as RNCalendar } from "react-native-calendars";

import COLORS from "../../utils/colors";
import { ICalendar, ICalendarState } from "./interface";
import styles from "./styles";

export const formatDate = (date: any) => {
  const selectedDate = new Date(date);
  const day = selectedDate.getDate();
  const month = selectedDate.toLocaleString("default", { month: "long" });

  const formattedDate = `${day} ${month}`;
  return formattedDate;
};

class Calendar<T extends ICalendar, S extends ICalendarState> extends Component<
  T,
  S
> {
  current;
  formatDate = formatDate;
  arrowRightUrl = "https://i.imgur.com/zuh7pXB.png";
  arrowLeftUrl = "https://i.imgur.com/5qQ3eEO.png";

  constructor(props: T) {
    super(props);

    const { currentDate, markedDates = {} } = this.props;

    const timezoneOffset = new Date().getTimezoneOffset();
    const today = new Date(Date.now() - timezoneOffset);

    const current = (currentDate || today).toISOString().split("T")[0];
    this.current = current;

    //@ts-ignore
    this.state = {
      isCalendarVisible: false,
      markedDates,
    };
  }

  getSelectedDate = (state: ICalendarState) => {
    const { markedDates } = state;

    const selectedDate = Object.keys(markedDates).find(
      (day) => markedDates[day].selected
    );

    if (!selectedDate) return;

    return this.formatDate(selectedDate);
  };

  handleOnDayPress = (day: any) => {
    const { dateString } = day;
    const { markedDates } = this.state;

    const _markedDates = JSON.parse(JSON.stringify(markedDates));

    Object.values(_markedDates).forEach((val: any) => delete val.selected);

    const updateMarkedDates = {
      ..._markedDates,
      [dateString]: {
        ..._markedDates[dateString],
        selected: true,
      },
    };

    this.setState({
      markedDates: updateMarkedDates,
    });
  };

  Calendar = (props: ICalendar) => {
    const {
      markedDates,
      style,
      arrowRight,
      arrowLeft,
      theme,
      onDayPress,
      ...rest
    } = props;

    return (
      <RNCalendar
        current={this.current}
        onDayPress={(e) => {
          this.handleOnDayPress(e);
          onDayPress && onDayPress(e);
        }}
        markedDates={markedDates ?? this.state.markedDates}
        style={[styles.calendarStyle, style]}
        renderArrow={(direction) => {
          return (
            <View style={styles.renderArrowStyle}>
              {direction == "right"
                ? arrowRight || (
                    <Image
                      source={{ uri: this.arrowRightUrl }}
                      style={styles.imgStyle}
                    />
                  )
                : arrowLeft || (
                    <Image
                      source={{ uri: this.arrowLeftUrl }}
                      style={styles.imgStyle}
                    />
                  )}
            </View>
          );
        }}
        theme={{
          calendarBackground: "transparent",
          selectedDayBackgroundColor: COLORS.COLOR_BLUE,
          selectedDayTextColor: COLORS.COLOR_HIGHLIGHTBLUE,
          textDayFontWeight: "400",
          textDayFontSize: 14,
          textSectionTitleColor: COLORS.COLOR_GRAY,
          todayTextColor: COLORS.COLOR_BLACK,
          dayTextColor: COLORS.COLOR_LIGHTBLACK,
          textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
          monthTextColor: COLORS.COLOR_LIGHTBLACK,
          textMonthFontWeight: "700",
          textMonthFontSize: 14,
          ...theme,
        }}
        {...rest}
      />
    );
  };

  render() {
    return this.Calendar(this.props);
  }
}

export default Calendar;
