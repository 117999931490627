import React from "react";
import { Image, StyleSheet } from "react-native";
import LinearGradientComponent from "../../LinearGradient/index";

export function CheckedIcon() {
  return (
    <LinearGradientComponent
      colors={["#FF002F", "#FF751B"]}
      style={styles.container}
    >
      <Image
        style={styles.imageStyle}
        source={{ uri: "https://i.imgur.com/lx3p2hw.png" }}
      />
    </LinearGradientComponent>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 5,
    justifyContent: "center",
    flexDirection: "row",
  },
  imageStyle: {
    width: 11,
    height: 11,
  },
});
