//@ts-nocheck
import React, { Component } from "react";
import {
  Pressable,
  View,
  Text,
  PressableProps,
  TextStyle,
  ViewStyle,
} from "react-native";
import styles from "./styles";
import CONSTANTS from "../../utils/constants";
import COLORS from "../../utils/colors";

export interface TextButtonProps extends PressableProps {
  theme?: "primary" | "secondary" | "dark";
  text?: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  onPress?: () => void;
}

interface TextButtonState {
  isHovered: boolean;
}

/**
 * @deprecated Since version 1.1.2 Will be integrated into Button component in newer releases
 */
class TextButton extends Component<TextButtonProps, TextButtonState> {
  constructor(props: TextButtonProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  primaryTextButton = () => {
    const { text, style, textStyle, icon, iconStyle, iconPosition, ...props } =
      this.props;
    const { isHovered } = this.state;
    return (
      <Pressable
        // onHoverIn={() => this.setState({ isHovered: true })}
        // onHoverOut={() => this.setState({ isHovered: false })}
        {...props}
      >
        <View
          style={[
            styles.textButton,
            { flexDirection: iconPosition === "left" ? "row" : "row-reverse" },
            style,
          ]}
        >
          {icon}
          {text && (
            <Text
              style={[
                styles.text,
                {
                  color: isHovered
                    ? COLORS.COLOR_DARKBLUE
                    : COLORS.COLOR_MEDIUMBLUE,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  secondaryTextButton = () => {
    const { text, style, textStyle, icon, iconStyle, iconPosition, ...props } =
      this.props;
    const { isHovered } = this.state;
    return (
      <Pressable
        // onHoverIn={() => this.setState({ isHovered: true })}
        // onHoverOut={() => this.setState({ isHovered: false })}
        {...props}
      >
        <View
          style={[
            { flexDirection: iconPosition === "left" ? "row" : "row-reverse" },
            styles.textButton,
            style,
          ]}
        >
          {icon}
          {text && (
            <Text
              style={[
                styles.text,
                {
                  color: isHovered ? COLORS.COLOR_DARKBLUE : COLORS.COLOR_GRAY,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  darkTextButton = () => {
    const { text, style, textStyle, icon, iconStyle, iconPosition, ...props } =
      this.props;
    const { isHovered } = this.state;
    return (
      <Pressable
        // onHoverIn={() => this.setState({ isHovered: true })}
        // onHoverOut={() => this.setState({ isHovered: false })}
        {...props}
      >
        <View
          style={[
            { flexDirection: iconPosition === "left" ? "row" : "row-reverse" },
            styles.textButton,
            style,
          ]}
        >
          {icon}
          {text && (
            <Text
              style={[
                styles.text,
                {
                  color: isHovered
                    ? COLORS.COLOR_LIGHTBLACK
                    : COLORS.COLOR_SUBTEXT,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  disableTextButton = () => {
    const { text, style, textStyle, icon, iconStyle, iconPosition, ...props } =
      this.props;
    return (
      <Pressable disabled {...props}>
        <View
          style={[
            { flexDirection: iconPosition === "left" ? "row" : "row-reverse" },
            styles.textButton,
            style,
          ]}
        >
          {icon}
          {text && (
            <Text
              style={[
                styles.text,
                { color: COLORS.COLOR_GRAY_PLACEHOLDER },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  render() {
    const { disabled, theme } = this.props;
    return disabled
      ? this.disableTextButton()
      : theme === CONSTANTS.THEME_PRIMARY
      ? this.primaryTextButton()
      : theme === CONSTANTS.THEME_DARK
      ? this.darkTextButton()
      : this.secondaryTextButton();
  }
}

export default TextButton;
