import React, { Component } from "react";
import { FlatList, ScrollView, Pressable, View, Text } from "react-native";
import style from "./style";
import { ListData, SideMenuListProps, SubListData } from "./interface";

interface state {
  subListId: any;
  subListTabId: any;
}

/**
 * @deprecated Since version 1.1.2. Please use SideNavigation instead. Will be removed in next release
 */

class SideMenuList extends Component<SideMenuListProps, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      subListId: this?.props?.listSubActiveIndex,
      subListTabId: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.listSubActiveIndex !== prevProps.listSubActiveIndex) {
      this.setState({
        subListId: this.props.listSubActiveIndex,
      });
    }
  }

  updaterenderSubState(index: any) {
    this.setState({
      ...this.state,
      subListTabId: index,
    });
  }

  renderSubSideMenuData = (item: SubListData, index: any) => {
    const {
      listSubViewTitleStyle,
      listSubViewTitleTouchStyle,
      listSubtTitleButtonStyle,
      listSubtTitleButtonTouchStyle,
    } = this?.props;
    const { subListTabId } = this?.state;

    const { id, title, onPress, ...pressableProps } = item;

    return (
      <Pressable
        key={id}
        style={
          subListTabId == index
            ? [
                style.listSubtTitleButtonTouchStyle,
                listSubtTitleButtonTouchStyle,
              ]
            : [style.listSubtTitleButtonStyle, listSubtTitleButtonStyle]
        }
        onPress={() => {
          this.updaterenderSubState(index);
          onPress && onPress(id as any);
        }}
        {...pressableProps}
      >
        <Text
          style={
            subListTabId == index
              ? [style.listSubViewTitleTouchStyle, listSubViewTitleTouchStyle]
              : [style.listSubViewTitleStyle, listSubViewTitleStyle]
          }
        >
          {title}
        </Text>
      </Pressable>
    );
  };

  updaterenderSideMenuData(id: any) {
    this.setState({
      ...this.state,
      subListId: id,
    });
  }

  renderSideMenuData = ({ item }: { item: ListData }) => {
    const {
      listSubTitlePressStyle,
      listSubTitleStyle,
      listSubViewTitleButtonStyle,
      listSubtTitleButtonPressStyle,
      ...rest
    } = this?.props;
    const { subListId } = this?.state;

    const { id, title, onPress, ...pressableProps } = item;

    return (
      <>
        <Pressable
          style={
            subListId == item?.id
              ? [
                  style.listSubtTitleButtonPressStyle,
                  listSubtTitleButtonPressStyle,
                ]
              : [style.listSubViewTitleButtonStyle, listSubViewTitleButtonStyle]
          }
          onPress={() => {
            this.updaterenderSideMenuData(id);
            onPress && onPress(id as any);
          }}
          {...pressableProps}
        >
          <Text
            style={
              subListId == item?.id
                ? [style.listSubTitlePressStyle, listSubTitlePressStyle]
                : [style.listSubTitleStyle, listSubTitleStyle]
            }
          >
            {item?.title}
          </Text>
        </Pressable>
        {subListId == item?.id && item?.data?.length > 0 && (
          <View style={style.subViewStyle}>
            <ScrollView
              nestedScrollEnabled={true}
              showsVerticalScrollIndicator={false}
            >
              {item?.data?.map((subItem: SubListData, index: any) => {
                return this.renderSubSideMenuData(subItem, index);
              })}
            </ScrollView>
          </View>
        )}
      </>
    );
  };

  render() {
    const { listData, sideBarContainer, sideBartitleStyle } = this?.props;
    const { subListId, subListTabId } = this?.state;
    return (
      <View style={style.mainView}>
        <View style={[style.sideBarContainer, sideBarContainer]}>
          <Text style={[style.sideBartitleStyle, sideBartitleStyle]}>
            {this.props.mainTitle}
          </Text>
          <View>
            <FlatList
              data={listData as ListData[]}
              keyExtractor={(item) => item.id}
              renderItem={(item) => this.renderSideMenuData(item)}
              extraData={[subListId, subListTabId]}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default SideMenuList;
