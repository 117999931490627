import { StyleSheet } from "react-native";
const hs = (value: number) => value;
const ws = (value: number) => value;
const ms = (value: number) => value;

export const styles = StyleSheet.create({
    container: {
        display: "flex",
    },
    placeHolderContainer: {
        display: "flex",
        height: hs(50),
        flexDirection: "row",
        width: "100%",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "space-between",
        borderWidth: ms(0),
        borderColor: "#1B1B3A",
        borderTopLeftRadius: ms(8),
        borderTopRightRadius: ms(8),
        paddingHorizontal: ws(16),
    },
    contentContainer: {
        display: "flex",
        position: "absolute",
        top: 50,
        left: 0,
        width: "100%",
        borderBottomLeftRadius: ms(8),
        borderBottomRightRadius: ms(8),
        backgroundColor: "white",
        marginTop: hs(0),
    },
    listContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        borderRadius: ms(0),
        backgroundColor: "transparent",
        marginTop: hs(2),
        padding: ws(16),
        alignItems: "center"

    },
    selectedListContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        width: "100%",
        borderRadius: ms(0),
        marginTop: hs(2),
        padding: ws(16),
        alignItems: "center",
        backgroundColor: "#E1CCFF"
    },
    placeholderTextStyle: {
        fontSize: ms(18),
        fontWeight: "300",
        color: "black",
    },
    imageContainer: {
        display: "flex",
        overflow: "hidden",
        alignSelf: "center"
    },
    iconContainer: {
        display: "flex",
        overflow: "hidden",
        marginRight: hs(8)
    },
    image: {
        height: hs(20),
        width: hs(20),
    }
})
