const COLORS = {
  COLOR_LIGHTBLUE: "#C399FF",
  COLOR_MEDIUMBLUE: "#6200EA",
  COLOR_DARKBLUE: "#5500CC",
  COLOR_WHITE: "#FFFFFF",
  COLOR_GREY: "#EBEBEB",
  COLOR_BLACK: "#000000",
  COLOR_LIGHTBLACK: "#0F172A",
  COLOR_RED: "#DC2626",
  COLOR_GRAY_PLACEHOLDER: "#94A3B8",
  COLOR_ERROR: "#F87171",
  COLOR_BLUE: "#E1CCFF",
  COLOR_GREY_INPUT: "#CBD5E1",
  COLOR_COOLGRAY: "#0F172A",
  COLOR_PURPLE: "#8833FF",
  COLOR_GRAY: "#64748B",
  COLOR_LIGHTGRAY: "#E2E8F0",
  COLOR_BTNGRAY: "#F1F5F9",
  COLOR_SEGMNTD: "#F0E5FF",
  COLOR_LIGHTPINK: "#F0E5FF",
  COLOR_HIGHLIGHTBLUE: "#6200EA",
  COLOR_SUBTEXT: "#334155",
  COLOR_SHADOW: "#171717",
  COLOR_HEADERTEXT: "#111827",
  COLOR_LISTTEXT: "#0F172A",
  COLOR_LIGHTPURPLE: "#F0E5FF",
  COLOR_PRIMARY_PURPLE: "#A666FF",
  COLOR_DISABLE_GREY: "#64748B",
  COLOR_CARDS_BG_GREY: "#EEEEEE",
  COLOR_NEUTRAL_GRAY: "#475569",
  COLOR_SUB_TEXT: "#475569",
  COLOR_ERROR_BGD: "#FEE2E2",
  COLOR_WARNING: "#FEF3C7",
  COLOR_INFO: "#F8FAFC",
  COLOR_WARNING_BORDER: "#D97706",
  COLOR_COMPLETED: "#34D399",
  GREY_TEXT: "#64748B",
};

export default COLORS;
