import React, { Component } from "react";
import {
  TextInput,
  View,
  Text,
  ViewStyle,
  TextStyle,
  TextInputProps,
  Platform,
} from "react-native";
import styles from "./styles";
import COLORS from "../../utils/colors";
import { Size } from "../../utils/constants";

export interface InputProps extends TextInputProps {
  size?: Size;
  error?: string;
  suffix?: string;
  prefix?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  labelTextStyle?: TextStyle;
  prefixStyle?: TextStyle;
  suffixStyle?: TextStyle;
  errorStyle?: TextStyle;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  borderColor?: string;
  borderHighlightColor?: string;
  borderErrorColor?: string;
  icon?: string;
}

interface InputState {
  value: string | null;
  borderColor: string;
}

export class Input extends Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props);
    this.state = {
      value: null,
      borderColor: props.borderColor,
    };
  }

  onChangeText = (value: string) => {
    if (value) {
      this.setState({ value });
      const { onChangeText } = this.props;
      if (onChangeText) {
        onChangeText(value);
      }
    }
  };

  onFocus() {
    this.setState({ borderColor: this.props.borderHighlightColor });
  }

  onBlur() {
    this.setState({ borderColor: this.props.borderColor });
  }

  render() {
    const {
      rightIcon,
      size,
      error,
      suffix,
      prefix,
      label,
      leftIcon,
      labelTextStyle,
      borderHighlightColor,
      borderColor,
      borderErrorColor,
      prefixStyle,
      suffixStyle,
      errorStyle,
      textStyle,
      containerStyle,
      icon,
      ...props
    } = this.props;

    return (
      <>
        {label && <Text style={[styles.label, labelTextStyle]}>{label}</Text>}
        <View
          style={[
            styles.container,
            {
              borderColor: error ? borderErrorColor : this.state.borderColor,
              height: this.getSize(size),
            },
            containerStyle,
          ]}
        >
          {leftIcon}
          {prefix && <Text style={[styles.fixes, prefixStyle]}>{prefix}</Text>}
          <TextInput
            style={[
              {
                ...styles.input,
                textAlign: suffix ? "right" : "left",
              },
              textStyle,
            ]}
            placeholderTextColor={this.props.borderColor}
            onChangeText={this.onChangeText}
            onBlur={(e) => {
              this.onBlur();
              props.onBlur && props.onBlur(e);
            }}
            onFocus={(e) => {
              this.onFocus();
              props.onFocus && props.onFocus(e);
            }}
            {...props}
          />
          {suffix && <Text style={[styles.fixes, suffixStyle]}>{suffix}</Text>}
          {rightIcon}
        </View>
        {!!error && (
          <View>
            <Text style={[styles.error, errorStyle]}>{error}</Text>
          </View>
        )}
      </>
    );
  }

  getSize: (Size) => number = (size = Size.Medium) => {
    switch (size) {
      case Size.Small:
        return 32;
      case Size.Large:
        return 56;
      case Size.Medium:
        return 44;
    }
  };
}

//@ts-ignore
Input.defaultProps = {
  borderHighlightColor: COLORS.COLOR_HIGHLIGHTBLUE,
  borderColor: COLORS.COLOR_GREY_INPUT,
  borderErrorColor: COLORS.COLOR_ERROR,
};
