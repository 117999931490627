import React, { useState } from "react";
import {
  PressableProps,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewProps,
} from "react-native";

import { CheckBox as Original, CheckBoxProps } from "@rneui/themed";
import { CheckBoxIconProps } from "@rneui/base/dist/CheckBox/components/CheckBoxIcon";

import { CheckedIcon } from "./molecules/CheckedIcon";
import { UncheckedIcon } from "./molecules/UnCheckedIcon";
import COLORS from "../../utils/colors";

export type CheckboxProps = Omit<CheckBoxProps, "checked"> &
  PressableProps &
  Omit<CheckBoxIconProps, "checked"> & {
    children?: undefined;
    checked?: boolean;
  };

export function CheckboxBase(props: CheckboxProps) {
  //@ts-ignore - To add internal state to the component
  return <Original size={20} {...props} />;
}

export function Checkbox(props: CheckboxProps) {
  const { style, onPress, checked, ...rest } = props;

  const [internalChecked, setInternalChecked] = useState(false);

  const _style = style
    ? typeof style === "function"
      ? style({ pressed: internalChecked })
      : style
    : {};

  return (
    <CheckboxBase
      title={"Press me"}
      checkedIcon={<CheckedIcon />}
      uncheckedIcon={<UncheckedIcon />}
      textStyle={{ fontFamily: "Arial" }}
      {...rest}
      checked={checked ?? internalChecked}
      containerStyle={[{ backgroundColor: "transparent" }, _style]}
      onPress={onPress ?? (() => setInternalChecked(!internalChecked))}
    />
  );
}

export type CheckboxGroupProps = {
  checkboxData: CheckboxProps[];
  title?: string;
  checkboxProps?: CheckboxProps;
  style?: StyleProp<ViewProps>;
  onPress?: (index: number) => void;
};

export function CheckboxGroup({
  title,
  checkboxData,
  checkboxProps,
  style,
  onPress,
}: CheckboxGroupProps) {
  return (
    <View style={[internalStyles.checkBoxGroupView, style]}>
      <Text style={[internalStyles.checkBoxGroupTitle]}>{title}</Text>
      {checkboxData?.map((checkboxEl: any, index: any) => {
        return (
          <Checkbox
            onPress={() => onPress(index)}
            {...checkboxProps}
            {...checkboxEl}
            key={index}
          />
        );
      })}
    </View>
  );
}

const internalStyles = StyleSheet.create({
  checkBoxGroupTitle: {
    fontSize: 14,
    color: COLORS.COLOR_GRAY,
    marginBottom: 15,
  },
  checkBoxGroupView: {
    backgroundColor: "white",
    padding: 10,
  },
});
